import Vue from 'vue'
import axios from './axios.js'

import App from './App.vue'
import router from './router'
import {storeInitializer} from './store'
import dayjs from 'dayjs'

// Global Components
import './global-components'
import '@/libs/font-awesome'
import '@/libs/toast'
import '@/libs/chains'

import VueClipboard from 'vue-clipboard2'
import '@/assets/scss/style.scss'

import '@/libs/blockchain_contracts/busd'
import '@/libs/blockchain_contracts/vesting'
import '@/libs/blockchain_contracts/staking'
import '@/libs/blockchain_contracts/3air'
import '@/libs/blockchain_contracts/collectibleNFTsContract'
import '@/libs/blockchain_contracts/stakingAPYCalculator'
import VueSocialSharing from 'vue-social-sharing'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'


async function initialize() {

    try {
        const configResponse = await axios.get('/config.json')
        Vue.prototype.appConfig = configResponse.data
    } catch (e) {
        console.error(e)
        return
    }


    Vue.use(FloatingVue)
    FloatingVue.options.themes.tooltip.delay = {show: 0, hide: 0}
    FloatingVue.options.distance = 8
    FloatingVue.options.overflowPadding = 0
    FloatingVue.options.themes.tooltip.handleResize = true
    

    Vue.use(VueClipboard)
    dayjs.extend(require('dayjs/plugin/advancedFormat'))
    dayjs.extend(require('dayjs/plugin/relativeTime'))
    Vue.use(VueSocialSharing)

    Vue.prototype.$dayjs = dayjs

    // ADDS BEARER JWT TO EVERY REQUEST
    axios.interceptors.request.use(
        config => {
            const jwt = localStorage.getItem('accessToken')
            if (jwt !== '') config.headers.common.Authorization = `Bearer ${jwt}`
            return config
        },
        error => Promise.reject(error)
    )

    axios.interceptors.response.use(
        response => {
            return Promise.resolve(response)
        }
        /*error => {
            if (error.response.status === 401) {
                router.push({ name: 'login' })
            }
            return Promise.reject(error)
        }*/
    )

    Vue.prototype.$http = axios

    Vue.config.productionTip = false

    const store = await storeInitializer()

    new Vue({
        router,
        store,
        render(h) { return h(App) }
    }).$mount('#app')

}

initialize()
