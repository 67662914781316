import {ethers} from 'ethers'
import Vue from 'vue'
import store from '@/store'
import chainAddresses from '@/libs/blockchain_contracts/chainAddresses'

const collectibleNFTsABI = require('./ABI/AirNFTs.sol/AirNFTs.json')

const collectibleNFTsContract = (network) => new ethers.Contract(chainAddresses[network].collectibleNFTContract, collectibleNFTsABI, store.state.chain.readProviders[network])

Vue.prototype.$collectibleNFTsContract = collectibleNFTsContract
export default collectibleNFTsContract