import Vue from 'vue'
const chains = {
  1: {
    name: 'Binance Smart Chain',
    shortName: 'BSC',
    logo: 'Binance_Logo.svg',
    logoFull: 'binance-logo-full.svg',
    currency: 'BUSD',
    mintedCurrency: {
      '0xe9e7cea3dedca5984780bafc599bd69add087d56': 'BUSD',
      '0x596834746b5b78f31a089ee7853fa595682824b7': '3AIR'
    }
  },
  // 2: {
  //     name: 'SKALE Network',
  //     shortName: 'SKALE',
  //     logo: 'SKALE_Logo.png',
  //     currency: 'USDC'
  // },
  3: {
    name: 'OKC',
    shortName: 'OKC',
    logo: 'OKC_Logo.png',
    logoFull: 'okc-logo-full.png',
    currency: 'USDT',
    mintedCurrency: {
      '0x382bb369d343125bfb2117af9c149795c6c65c50': 'USDT'
    }
  }
}

Vue.prototype.$getChain = (chainId) => chains[chainId]
Vue.prototype.$getChainMintedCurrencyByAddress = (chainId, mintedCurrencyAddress) => {
  return chains[chainId].mintedCurrency[mintedCurrencyAddress.toLowerCase()]
}
Vue.prototype.$chains = chains
export default chains
