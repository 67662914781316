<template>
    <drop-component class="relative mt-1" drop-class="default">
        <template #button>
            <div class="px-3 py-2 mt-1">
                <fa icon="ellipsis-vertical" size="2xl"/>
            </div>
        </template>
        <template #content>
            <div class="text-secondary flex flex-col text-right pr-4 gap-4 py-4 three-dots-menu">
                <a href="https://3air.io/" target="_blank">About 3air</a>
                <a href="https://docs.3air.io/faq/general-questions/" target="_blank">Help center</a>
                <a href="https://docs.3air.io/" target="_blank">Docs</a>
                <a href="https://docs.3air.io/legal/privacy-policy/" target="_blank">Legal and privacy</a>
                <div class="hr ml-4"></div>
                <a href="https://creator.3air.io" target="_blank">Creators portal</a>
            </div>
        </template>
    </drop-component>
</template>
<script>
    import DropComponent from '@/components/DropComponent'
    export default {
        components: {DropComponent}
    }
</script>

<style scoped lang="scss">
@import '@/assets/scss/style.scss';
.hr {
  border-top: 2px solid #FFFFFF;
}
.three-dots-menu {
  min-width: 200px !important;
}
a {
  color: #828282;
}
a:hover {
  color: #EB1E4E;
}
</style>
