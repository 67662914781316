<template>
    <!--  disabling hover event on mobile devices-->
    <div class="menu pointer-events-none md:pointer-events-auto" :class="{'open': isOpen}">
        <span></span>
        <span class="middle-line"></span>
        <span></span>
    </div>
</template>
<script>
    export default {
        props:{
            isOpen: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
<style scoped lang="scss">
@import '@/assets/scss/style.scss';
.menu {
  width: 25px;
  height: 25px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}

.menu span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: $text-primary;
  border-radius: 10px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.middle-line {
  width: 80% !important;
}

.menu span:nth-child(1) {
  top: 0;
  transform-origin: left center;
}

.menu span:nth-child(2) {
  top: 8px;
  transform-origin: left center;
}

.menu span:nth-child(3) {
  top: 16px;
  transform-origin: left center;
}

.menu:hover span:nth-child(1),
.menu.open span:nth-child(1) {
  transform: rotate(45deg);
  top: 0;
  left: 2px;
  background: $text-danger;
}

.menu:hover span:nth-child(2),
.menu.open span:nth-child(2){
  width: 0;
  opacity: 0;
}

.menu:hover span:nth-child(3),
.menu.open span:nth-child(3){
  transform: rotate(-45deg);
  top: 18px;
  left: 2px;
  background: $text-danger;
}

</style>
