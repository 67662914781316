<template>
    <div class="desktop-menu bg-primary w-full">
        <div class="flex items-center justify-start lg:grid lg:grid-cols-12">
            <div class="lg:col-span-2 mr-4 flex items-center mt-2 h-full">
                <router-link to="/" class="h-full">
                    <img width="110px" alt="logo" :src="require('@/assets/3air_logo.svg')">
                </router-link>
            </div>
            <div class="w-full lg:col-span-10 flex items-end">
                <div class="nav-link flex items-center text-primary gap-6 lg:gap-10">
                    <desktop-dropdown-menu class="mt-4 ml-2"/>
                    <router-link class="flex items-center" to="/news">
                        <NewsIcon class="icon pb-1"/>
                        <span class="ml-2">NEWS</span>
                    </router-link>
                    <router-link class="nav-item flex items-center" :class="{ 'router-link-exact-active': ($route.name === 'nft' || $route.name === 'ntfs')}" to="/nfts">
                        <HexagonIcon class="icon pb-1" />
                        <span class="ml-2">MARKETPLACE</span>
                    </router-link>
                    <router-link class="flex items-center" to="/schools">
                        <SchoolIcon class="icon pb-1"/>
                        <span class="ml-2">CONNECT A SCHOOL</span>
                    </router-link>
                    <router-link class="flex items-center" to="/enter-giveaway">
                        <GiftIcon class="icon pb-1"/>
                        <span class="ml-2">ENTER GIVEAWAY</span>
                    </router-link>

                </div>

                <div class="ml-auto flex items-center" style="position: relative;">
                    <div v-if="$store.getters.loggedIn" class="mr-4 md:mr-6 z-40">
                        <chains-dropdown-menu/>
                    </div>

                    <div v-if="$store.getters.loggedIn" class="mr-2 z-40">
                        <profile-dropdown-menu/>
                    </div>
                    <Button v-else @click="$router.push({name: 'login'})" class="mx-2">CONNECT WALLET</Button>
                    <three-dots-menu/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DesktopDropdownMenu from '@/components/navbar/components/DesktopDropdownMenu'
    import NewsIcon from '@/assets/icons/NewsIcon'
    import HexagonIcon from '@/assets/icons/HexagonIcon'
    import SchoolIcon from '@/assets/icons/SchoolIcon'
    import GiftIcon from '@/assets/icons/GiftIcon'
    import ChainsDropdownMenu from '@/components/navbar/components/ChainsDropdownMenu'
    import ProfileDropdownMenu from '@/components/navbar/components/ProfileDropdownMenu'
    import ThreeDotsMenu from '@/components/navbar/components/ThreeDotsMenu'
    import Button from '@/components/Button'
    export default {
        components: {
            ThreeDotsMenu,
            ProfileDropdownMenu,
            ChainsDropdownMenu,
            NewsIcon,
            HexagonIcon,
            SchoolIcon,
            GiftIcon,
            DesktopDropdownMenu,
            Button
        },
        data() {
            return {
                switchChainClicked: false
            }
        },
        methods: {
            async logout() {
                await this.$store.dispatch('disconnectWallet')
            },
            changeChain(chain) {
                this.$store.dispatch('switchChain', chain.shortName.toLowerCase())
                this.$refs.switchChains.show = false
                this.$refs.switchChains.mouseover = false
            },
            balance(name) {
                let fullText = ''

                switch (name.toLowerCase()) {
                case 'bsc':
                    fullText += `${this.$store.getters.balancesNormalized.bsc.busd.toFixed(2)} BUSD`
                    break
                case 'okc':
                    fullText += `${this.$store.getters.balancesNormalized.okc.usdt.toFixed(2)} USDT`
                    break
                }
                return fullText
            }
        },
        computed:{
            getShortAddress() {
                if (!this.$store.state.chain.accountData.address) return ''
                const wallet = this.$store.state.chain.accountData.address
                return `${wallet.substring(0, 6)}...${wallet.substring(wallet.length - 5, wallet.length)}`
            },
            currentNetwork() {
                return this.$store.getters.networkByName(this.$store.state.chain.connectedWalletData.chainId)
            },
            activeChain() {
                let chain = null

                const currentNetwork = this.currentNetwork
                if (currentNetwork) {
                    for (const item in this.$chains) {
                        if (this.$chains[item].shortName.toLowerCase() === currentNetwork.codename.toLowerCase()) {
                            chain = this.$chains[item]
                        }
                    }
                }
                return chain
            }
        }
    }
</script>

<style scoped lang="scss">
@media screen and (max-width: 1344px) {
  //.desktop-menu {
  //  position: fixed;
  //  left: 0;
  //  top: 0;
  //  padding: 1rem 5.6rem;
  //  z-index: 50 !important;
  //  width: 100%;
  //}
  .router-view-small {
    margin-top: 60px !important;
  }
  .router-view-big {
    margin-top: 130px !important;
  }
}
//@media screen and (max-width: 868px) {
//  .desktop-menu {
//    padding: 1rem 1.5rem;
//  }
//}
//@media screen and (max-width: 576px) {
//  .desktop-menu {
//    padding: 1rem 1rem;
//  }
//}
@import '@/assets/scss/style.scss';
.icon {
  width: 22px;
}
nav .nav-link a:hover {
  color: $text-danger;
}
</style>
