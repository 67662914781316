import Vue from 'vue'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import ToastificationContent from '../components/ToastificationContent'

Vue.prototype.$printSuccess = function(message) {
  this.$toast.success({
    component: ToastificationContent,
    props: {
      text: message,
      variant: 'success'
    }
  })
}

Vue.prototype.$printError = function(message) {
  this.$toast.error({
    component: ToastificationContent,
    props: {
      text: message,
      variant: 'error'
    }
  })
}


Vue.use(Toast, {
  hideProgressBar: true,
  closeOnClick: false,
  icon: false,
  timeout: 3000,
  closeButton: false,
  transition: 'Vue-Toastification__fade',
  newestOnTop: true
})
