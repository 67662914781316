<template>
    <drop-component drop-class="dropdown-content" @dropdown-changed="onDropdownChanged" :isOpen="isMenuOpen">
        <template #button>
            <div class="relative w-full"
                 @mouseover="isMenuOpen = true"
                 @mouseleave="isMenuOpen = false">
                <animated-hamburger-icon :isOpen="isMenuOpen" />
                <div class="absolute -mb-4 -ml-4 opacity-0"></div>
            </div>
        </template>
        <template #content>
            <div class="bg-black py-3 pl-3 pr-8 border-rounded flex flex-col gap-1.5 desktop-dropdown-menu">
                <router-link class="nav-item flex items-center p-2" to="/">
                    <HouseIcon class="icon" />
                    <span class="ml-4 text-small">DASHBOARD</span>
                </router-link>
                <router-link class="nav-item flex items-center p-2" :to="{name: 'collection', params: {id: 'BSC-4'}}">
                    <HexagonIcon class="icon" />
                    <span class="ml-4 text-small">CONNECTIVITY NFTs</span>
                </router-link>
                <router-link v-if="$store.getters.loggedIn" class="nav-item flex items-center p-2" to="/claim">
                    <AirIcon class="icon" />
                    <span class="ml-4 text-small">CLAIM 3AIR</span>
                </router-link>
                <router-link class="nav-item flex items-center p-2" to="/3air">
                    <AirIcon class="icon" />
                    <span class="ml-4 text-small">3AIR TOKEN</span>
                </router-link>
                <router-link class="nav-item flex items-center p-2" to="/staking">
                    <AirIcon class="icon" />
                    <span class="ml-4 text-small">STAKING</span>
                </router-link>
                <router-link class="nav-item flex items-center p-2" to="/swap">
                    <AirIcon class="icon" />
                    <span class="ml-4 text-small">SWAP</span>
                </router-link>
                <router-link class="nav-item flex items-center p-2" to="/staking-calculator">
                    <CalculatorIcon class="icon" />
                    <span class="ml-4 text-small">STAKING CALCULATOR</span>
                </router-link>
                <router-link class="nav-item flex items-center p-2" to="/coverage-map">
                    <fa icon="location-dot" class="icon"/>
                    <span class="ml-4 text-small">COVERAGE MAP</span>
                </router-link>
            </div>
        </template>
    </drop-component>
</template>
<script>
    import DropComponent from '@/components/DropComponent'
    import AirIcon from '@/assets/icons/AirIcon'
    import HouseIcon from '@/assets/icons/HouseIcon'
    import HexagonIcon from '@/assets/icons/HexagonIcon'
    import CalculatorIcon from '@/assets/icons/CalculatorIcon'
    import AnimatedHamburgerIcon from '@/assets/icons/AnimatedHamburgerIcon'
    export default  {
        components: {
            AnimatedHamburgerIcon,
            DropComponent,
            AirIcon,
            HouseIcon,
            HexagonIcon,
            CalculatorIcon},
        data() {
            return {
                isMenuOpen: false
            }
        },
        methods: {
            onDropdownChanged(dropdownOpened) {
                this.isMenuOpen = dropdownOpened
            }
        }
    }
</script>
<style scoped lang="scss">
@import '@/assets/scss/style.scss';
.nav-item:hover {
  color: $text-danger;
}
.icon {
  width: 22px;
}
.desktop-dropdown-menu:after {
  opacity: 0;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: -15px;
  left: -10px;
  z-index: -1;
}
</style>
