import {ethers} from 'ethers'
import Vue from 'vue'
import store from '@/store'
import chainAddresses from '@/libs/blockchain_contracts/chainAddresses'

const stakingAPYCalculatorABI = require('./ABI/StakingAPYCalculator.sol/StakingAPYCalculator.json')

const stakingAPYCalculatorContract = () => new ethers.Contract(chainAddresses.bsc.stakingAPYCalculator, stakingAPYCalculatorABI, store.state.chain.readProviders.bsc)

Vue.prototype.$stakingAPYCalculatorContract = stakingAPYCalculatorContract
export default stakingAPYCalculatorContract