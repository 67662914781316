import dayjs from 'dayjs'

const app = {
    state: {
        currentTimestamp: ((new Date().getTime()) / 1000).toFixed(0),
        randomSeed: 0,
        enteredDashboard: false,
        school_nfts_config: {
            collection_ids: ['BSC-4']
        }
    },
    getters: {
      enteredDashboard(state) {
        return state.enteredDashboard
      },
      getRandomSeed(state) {
        return state.randomSeed
      },
      getCollectionId(state) {
        return state.school_nfts_config.collection_ids[0]
      },
      isSchoolNFT: (state) => (id) => {
        return state.school_nfts_config.collection_ids.includes(id)
      }
    },
    mutations: {
        SET_ENTERED_DASHBOARD(state) {
            state.enteredDashboard = true
        },
        SET_RANDOM_SEED(state, seed) {
            state.randomSeed = seed
        }
    },
    actions: {
        setEnteredDashboard({ commit }) {
            commit('SET_ENTERED_DASHBOARD')
        },
        setRandomSeed({commit}) {
            commit('SET_RANDOM_SEED', dayjs().unix())
        }
    }
}

setInterval(() => {
    app.state.currentTimestamp = (((new Date().getTime()) / 1000)).toFixed(0)
}, 1000)


export default app
