import store from '@/store/index'
import axios from 'axios'
import Vue from 'vue'

export default {
  state: {
    loggedIn: false,
    ownedNFTIDs: []
  },
  getters: {
    loggedIn() {
      if (!store.getters.address) return false
      else return true
    },
    isNFTOwner: (state) => (id) => {
      return state.ownedNFTIDs.includes(id)
    }
  },
  mutations: {
    SET_LOG_IN(state) {
      state.loggedIn = true
    },
    SET_LOG_OUT(state) {
      state.loggedIn = false
    },
    SET_OWNED_NFT_IDS(state, ids) {
      state.ownedNFTIDs = ids
    }
  },
  actions: {
    setLogin({ commit }) {
      commit('SET_LOG_IN')
      localStorage.setItem('loggedIn', 'true')
    },
    setLogout({ commit }) {
      commit('SET_LOG_OUT')
      localStorage.setItem('loggedIn', 'false')
    },
    loadUserOwnedNFTs({ commit }) {
      if (!store.state.chain.accountData.address) return

      axios.get(`/api/public/v1/nft/minted_nfts/${store.state.chain.accountData.address}?$page=1&perPage=1000`)
        .then((res) => {
          if (!res.data || !res.data.items || !res.data.items.length) return
          const ownedNFTsIds = res.data.items.map(mintedNFT => mintedNFT.nft.id)
          commit('SET_OWNED_NFT_IDS', ownedNFTsIds)
        })
        .catch(() => {
          Vue.prototype.$printError('There was an error retreiving your NFTs! Please refresh your website!')
        })
    }
  }
}
