<template>
    <div>
        <div class="flex items-center">
            <div class="mr-8">
                <fa v-if="variant === 'success'" class="toast-icon-success" icon="circle-check" size="xl"/>
                <fa v-else-if="variant === 'error'" class="toast-icon-error" icon="circle-exclamation" size="xl"/>
            </div>
            <div class="toast-text montserrat" :class="[text.length > 40 ? 'text-extra-small' : 'text-medium']">
                {{text}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        props: {
            variant: {
                type: String,
                default: 'primary'
            },
            text: {
                type: String,
                default: null
            }
        }
    }
</script>

<style scoped lang="scss">
@import '@/assets/scss/style.scss';
.toast-icon-success {
  color: $toast-primary;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3), inset 6px 6px 20px rgba(0, 0, 0, 0.25);
}
.toast-icon-error {
  color: $toast-danger;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3), inset 6px 6px 20px rgba(0, 0, 0, 0.25);
}
.toast-text {
  color: $text-primary;
}

</style>
<style lang="scss">
@import '@/assets/scss/style.scss';

.Vue-Toastification__toast {
  padding: 18px 20px 18px 12px !important;
  background: rgba($background-primary, 0.5) !important;
  box-shadow: 6px 6px 9px rgba(0, 0, 0, 0.25), inset 20px 20px 40px rgba(0, 0, 0, 0.25) !important;
  backdrop-filter: blur(100px) !important;
  border-radius: 7px !important;
}
.Vue-Toastification__toast--success {
  background: linear-gradient(to left bottom, rgba($toast-primary,0.25), $background-primary 70%) !important;
}
.Vue-Toastification__toast--error {
  background: linear-gradient(to left bottom,  rgba($toast-danger,0.25), $background-primary 60%) !important;
}
</style>
