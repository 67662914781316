<template>
    <div class="dropdown-main">
        <button @click="onButtonClicked">
            <slot name="button"/>
        </button>
        <div :class="[isOpen || show ? 'show-dropdown' : 'hidden', dropClass, additionalDropdownClass]"
             class="z-50"
             @mouseover="mouseover = true"
             @mouseleave="mouseover = false">
            <slot name="content"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DropComponent',
        props: {
            dropClass: {
                type: String
            },
            isOpen: {
                type: Boolean,
                default: false
            },
            additionalDropdownClass: {
                type: String
            }
        },
        methods: {
            onButtonClicked() {
                this.show = !this.show
                this.$emit('dropdown-changed', this.show)
            },
            onClick(e) {
                if (!this.$el.contains(e.target)) {
                    this.show = false
                    this.$emit('dropdown-changed', this.show)
                }
            }
        },
        data() {
            return {
                show: false,
                mouseover: false,
                eventListener: null
            }
        },
        watch: {
            $route() {
                this.show = false
                this.mouseover = false
            },
            mouseover() {
                this.$emit('dropdown-changed', this.mouseover)
            }
        },
        mounted() {
            document.addEventListener('click', this.onClick)
        },
        beforeDestroy() {
            document.removeEventListener('click', this.onClick)
        }
    }
</script>

<style scoped>
.dropdown-main {
  /*position: relative;*/
  /*display: inline-block;*/
}
.show-dropdown {
  display: block !important;
}
.dropdown-content {
  display: block;
  position: absolute;
}
.default {
  position: absolute;
  /*top: 35px;*/
  right: 0;
  background: #000000;
  border-radius: 7px;
  /*z-index: 51;*/
  /*width: 100%;*/
}
.default > div:hover {
  color: #EB1E4E;
}

@media screen and (max-width: 576px) {
  .default {
    width: auto;
    min-width: 190px;
  }
}

/* Dropup content (Hidden by Default) */
.dropdown-main .dropup-content {
  display: none;
  position: absolute;
  bottom: 50px;
  left: -80px;
  min-width: 140px; /* to odstrani da se crnega znebimo */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border: 5px solid black;
  border-radius: 5px;
}
/* Links inside the dropup */
.dropdown-main .dropup-content a {
  background: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: end;
}
</style>
