<template>
    <drop-component ref="switchChains"
                    drop-class="default"
                    additional-dropdown-class="w-full"
                    @dropdown-changed="onDropdownChanged"
                    :is-open="isMenuOpen"
                    class="relative"
    >
        <template #button>
            <!--          p-2 md:py-2 md:px-5-->
            <div class="chain-div bg-primary px-3 py-1 border-rounded flex flex-col items-center cursor-pointer w-full box-shadow">
                <div class="flex items-center" v-if="activeChain">
                    <img :alt="`${activeChain.shortName} Logo`" class="chainIcon" :src="require(`@/assets/chains/${activeChain.logo}`)" />
                    <span class="text-extra-small font-weight-medium hidden sm:block text-center px-4">{{balance(activeChain.shortName)}}</span>
                </div>
                <p class="text-extra-small font-weight-medium" v-else>
                    Switch Chain
                </p>
            </div>
        </template>
        <template #content>
            <div v-if="activeChain" class="sm:hidden border-rounded p-2 md:py-2 md:px-5 flex flex-row items-center cursor-pointer w-full bg-primary box-shadow"
                 @click="changeChain(activeChain)">
                <img :alt="`${activeChain.shortName} Logo`" class="chainIcon mr-4" :src="require(`@/assets/chains/${activeChain.logo}`)" />
                <p class="text-extra-small font-weight-medium">{{balance(activeChain.shortName)}}</p>
            </div>
            <div v-for="item of $chains" :key="'chain_'+item.shortName" @click="changeChain(item)"
                 class="border-rounded p-2 md:py-2 md:px-5 flex flex-row items-center cursor-pointer w-full box-shadow"
                 :class="[(currentNetwork && (currentNetwork.codename.toLowerCase() === item.shortName.toLowerCase()) || switchChainClicked) ? 'hidden':'']">
                <img :alt="`${item.shortName} Logo`" class="chainIcon mr-4" :src="require(`@/assets/chains/${item.logo}`)" />
                <p class="text-extra-small font-weight-medium">{{balance(item.shortName)}}</p>
            </div>
        </template>
    </drop-component>
</template>

<script>
    import DropComponent from '@/components/DropComponent'
    export default {
        components: {
            DropComponent
        },
        data() {
            return {
                switchChainClicked: false,
                isMenuOpen: false
            }
        },
        methods: {
            changeChain(chain) {
                this.$store.dispatch('switchChain', chain.shortName.toLowerCase())
                this.$refs.switchChains.show = false
                this.$refs.switchChains.mouseover = false
            },
            balance(name) {
                let fullText = ''

                switch (name.toLowerCase()) {
                case 'bsc':
                    fullText += `${this.$store.getters.balancesNormalized.bsc.busd.toFixed(2)} BUSD`
                    break
                case 'okc':
                    fullText += `${this.$store.getters.balancesNormalized.okc.usdt.toFixed(2)} USDT`
                    break
                }
                return fullText
            },
            onDropdownChanged(open) {
                this.isMenuOpen = open
            }
        },
        computed:{
            currentNetwork() {
                return this.$store.getters.networkByName(this.$store.state.chain.connectedWalletData.chainId)
            },
            activeChain() {
                let chain = null

                const currentNetwork = this.currentNetwork
                if (currentNetwork) {
                    for (const item in this.$chains) {
                        if (this.$chains[item].shortName.toLowerCase() === currentNetwork.codename.toLowerCase()) {
                            chain = this.$chains[item]
                        }
                    }
                }
                return chain
            }
        }
    }
</script>
<style scoped>
.box-shadow {
  box-shadow: 7px 9px 6px rgba(0, 0, 0, 0.3), inset 6px 6px 20px rgba(0, 0, 0, 0.25);
}
.chain-div {
  /*height: 35px;*/
}
.chainIcon{
  width: 18px;
  height: 18px;
}
</style>
