import {ethers} from 'ethers'
import Vue from 'vue'
import store from '@/store'
import chainAddresses from '@/libs/blockchain_contracts/chainAddresses'

const erc20ABI = require('./ABI/ERC20.sol/ERC20.json')

const lpTokenContract = () => new ethers.Contract(chainAddresses.bsc.lpToken, erc20ABI, store.state.chain.readProviders.bsc)

Vue.prototype.$lpTokenContract = lpTokenContract
export default lpTokenContract
