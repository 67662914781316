import {ethers} from 'ethers'
import Vue from 'vue'
import store from '@/store'
import chainAddresses from '@/libs/blockchain_contracts/chainAddresses'

const vestingABI = require('./ABI/Vesting.sol/Vesting.json')

const vestingContract = () => new ethers.Contract(chainAddresses.bsc.vesting, vestingABI, store.state.chain.readProviders.bsc)

Vue.prototype.$vestingContract = vestingContract
export default vestingContract