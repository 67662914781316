<script>
    import HomeIcon from 'vue-feather-icons/icons/HomeIcon'
    import ChevronDownIcon from 'vue-feather-icons/icons/ChevronDownIcon'
    import RotateCwIcon from 'vue-feather-icons/icons/RotateCwIcon'
    import XIcon from 'vue-feather-icons/icons/XIcon'
    import CodeIcon from 'vue-feather-icons/icons/CodeIcon'
    import ArrowUpIcon from 'vue-feather-icons/icons/ArrowUpIcon'
    import FacebookIcon from 'vue-feather-icons/icons/FacebookIcon'
    import InstagramIcon from 'vue-feather-icons/icons/InstagramIcon'
    import SettingsIcon from 'vue-feather-icons/icons/SettingsIcon'
    import MenuIcon from 'vue-feather-icons/icons/MenuIcon'
    import StarIcon from 'vue-feather-icons/icons/StarIcon'
    import CreditCardIcon from 'vue-feather-icons/icons/CreditCardIcon'
    import HelpCircleIcon from 'vue-feather-icons/icons/HelpCircleIcon'
    import LogOutIcon from 'vue-feather-icons/icons/LogOutIcon'
    import Trash2Icon from 'vue-feather-icons/icons/Trash2Icon'
    import ZoomInIcon from 'vue-feather-icons/icons/ZoomInIcon'
    import SearchIcon from 'vue-feather-icons/icons/SearchIcon'
    import EyeIcon from 'vue-feather-icons/icons/EyeIcon'
    import CalendarIcon from 'vue-feather-icons/icons/CalendarIcon'
    import ClipboardIcon from 'vue-feather-icons/icons/ClipboardIcon'
    import ChevronRightIcon from 'vue-feather-icons/icons/ChevronRightIcon'
    import ChevronLeftIcon from 'vue-feather-icons/icons/ChevronLeftIcon'
    import InfoIcon from 'vue-feather-icons/icons/InfoIcon'
    
    const icons = {
        ClipboardIcon,
        HomeIcon,
        ChevronDownIcon,
        RotateCwIcon,
        XIcon,
        CodeIcon,
        ArrowUpIcon,
        FacebookIcon,
        InstagramIcon,
        SettingsIcon,
        MenuIcon,
        StarIcon,
        CreditCardIcon,
        HelpCircleIcon,
        LogOutIcon,
        Trash2Icon,
        ZoomInIcon,
        SearchIcon,
        EyeIcon,
        CalendarIcon,
        ChevronRightIcon,
        ChevronLeftIcon,
        InfoIcon
    }

    export default {
        name: 'FeatherIcon',
        functional: true,
        props: {
            icon: {
                required: true,
                type: [String, Object]
            },
            size: {
                type: String,
                default: '14'
            },
            badge: {
                type: [String, Object, Number],
                default: null
            },
            badgeClasses: {
                type: [String, Object, Array],
                default: 'badge-primary'
            }
        },
        render(h, { props, data }) {
            // Create Feather Icon
            const svg = h(icons[props.icon], { props: { size: props.size }, ...data })

            // If no badge is provided => Render just SVG
            if (!props.badge) return svg

            // Badge
            const badgeEl = h('span', { staticClass: 'badge badge-up badge-pill', class: props.badgeClasses }, [props.badge])

            // Return span with children
            return h('span', { staticClass: 'feather-icon position-relative' }, [svg, badgeEl])
        }
    }
</script>

<style lang="scss">
svg.feather {
  outline: none
}
</style>
