import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes'
import {faBars} from '@fortawesome/free-solid-svg-icons/faBars'
import {faEllipsisVertical} from '@fortawesome/free-solid-svg-icons/faEllipsisVertical'
import {faShareNodes} from '@fortawesome/free-solid-svg-icons/faShareNodes'
import {faSearch} from '@fortawesome/free-solid-svg-icons/faSearch'
import {faLinkedin} from '@fortawesome/free-brands-svg-icons/faLinkedin'
import {faLinkedinIn} from '@fortawesome/free-brands-svg-icons/faLinkedinIn'
import {faTelegram} from '@fortawesome/free-brands-svg-icons/faTelegram'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faFacebook} from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faWhatsapp} from '@fortawesome/free-brands-svg-icons/faWhatsapp'
import { faDiscord } from '@fortawesome/free-brands-svg-icons/faDiscord'
import { faHashtag } from '@fortawesome/free-solid-svg-icons/faHashtag'
import { faAt } from '@fortawesome/free-solid-svg-icons/faAt'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import {faCircleCheck} from '@fortawesome/free-solid-svg-icons/faCircleCheck'
import {faCircleExclamation} from '@fortawesome/free-solid-svg-icons/faCircleExclamation'
import { faCircleArrowDown } from '@fortawesome/free-solid-svg-icons/faCircleArrowDown'
import {faCopy} from '@fortawesome/free-solid-svg-icons/faCopy'
import {faRightLeft} from '@fortawesome/free-solid-svg-icons/faRightLeft'
import { faArrowsUpDown } from '@fortawesome/free-solid-svg-icons/faArrowsUpDown'
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons/faMapLocationDot'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons/faLocationDot'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons/faCircleInfo'
import { faCalculator } from '@fortawesome/free-solid-svg-icons/faCalculator'
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome'
import { faGift } from '@fortawesome/free-solid-svg-icons/faGift'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons/faCaretRight'


library.add(
  faTimes,
  faBars,
  faEllipsisVertical,
  faShareNodes,
  faSearch,
  faLinkedinIn,
  faTelegram,
  faTwitter,
  faDiscord,
  faChevronDown,
  faHashtag,
  faAt,
  faChevronUp,
  faCircleCheck,
  faCircleExclamation,
  faCircleArrowDown,
  faCopy,
  faRightLeft,
  faArrowsUpDown,
  faMapLocationDot,
  faCircleInfo,
  faFacebook,
  faWhatsapp,
  faLinkedin,
  faCalculator,
  faHome,
  faLocationDot,
  faGift,
  faCaretRight
)

// Register the component globally
Vue.component('fa', FontAwesomeIcon)
