import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView')
  },
  {
    path: '/nfts',
    name: 'nfts',
    component: () => import('@/views/Nfts')
  },
  {
    path: '/swap',
    name: 'swap',
    component: () => import('@/views/Swap')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/News')
  },
  {
    path: '/staking-calculator',
    name: 'staking-calculator',
    component: () => import('@/views/StakingCalculator')
  },
  {
    path: '/collection/:id',
    name: 'collection',
    component: () => import('@/views/Collection')
  },
  {
    path: '/nft/:id',
    name: 'nft',
    component: () => import('@/views/Nft')
  },
  {
    path: '/nft/preview/:id',
    name: 'nft-preview',
    component: () => import('@/views/NftPreview')
  },
  {
    path: '/author/:address',
    name: 'author',
    component: () => import('@/views/Author')
  },
  {
    path: '/author/:address/nfts',
    name: 'author-nfts',
    component: () => import('@/views/AuthorNfts')
  },
  {
    path: '/claim',
    name: 'claim',
    component: () => import('@/views/Claim'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/staking',
    name: 'staking',
    component: () => import('@/views/Staking')
  },
  {
    path: '/my-nfts',
    name: 'my-nfts',
    component: () => import('@/views/MyNfts'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/3air',
    name: '3air',
    component: () => import('@/views/3Air')
  },
  {
    path: '/my-nft/:id',
    name: 'my-nft',
    component: () => import('@/views/MyNft'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/enter-giveaway',
    name: 'enter-giveaway',
    component: () => import('@/views/EnterGiveaway'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/schools',
    name: 'schools',
    component: () => import('@/views/schools/Schools')
  },
  {
    path: '/school/:id',
    name: 'school',
    component: () => import('@/views/schools/School')
  },
  {
    path: '/coverage-map',
    name: 'coverage-map',
    component: () => import('@/views/coverageMap/CoverageMap')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login')
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue')
  },
  {
    path: '*',
    redirect: 'error-404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if ((to.meta && to.meta.requiresAuth && !store.getters.loggedIn)) {
    return next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  }

  next()
})

export default router
