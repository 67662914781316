<template>
    <div :class="{'w-full': block}" >
        <button @click="$emit('click')" class="button text-small montserrat font-weight-medium" :class="{'w-full': block}" :disabled="disabled">
            <slot/>
            <span class="disabled"/>
        </button>

    </div>
</template>

<script>
    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            block: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

.button {
  position: relative;
  padding: 8px 28px 5px 25px !important;
  background: $text-danger;
  box-shadow: 0.763rem 0.98rem 0.654rem rgba(0, 0, 0, 0.3), inset 0.654rem 0.654rem 2.179rem rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  color: $text-primary;
  height: 100%;
}
.button:hover {
  box-shadow: 0.763rem 0.98rem 0.654rem rgba(0, 0, 0, 0.3), inset 3.125rem 3.125rem 3.125rem rgba(0, 0, 0, 0.25);
  color: $button-primary-hover;
}

button:disabled .disabled,
button[disabled] .disabled{
  background: rgba(28, 35, 47, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
</style>
