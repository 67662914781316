<template>
    <drop-component drop-class="dropdown-content"
                    :isOpen="isMenuOpen"
                    @dropdown-changed="onDropdownChanged"
    >
        <template #button>
            <div class="relative w-full" @click="onHamburgerIconClicked">
                <animated-hamburger-icon :isOpen="isMenuOpen" />
                <div v-if="isMenuOpen" class="absolute -mb-4 -ml-4"></div>
            </div>
        </template>
        <template #content>
            <div class="bg-black py-3 pl-3 pr-8 border-rounded flex flex-col gap-1.5">
                <router-link class="nav-item flex items-center p-2" to="/">
                    <HouseIcon class="icon" />
                    <span class="ml-4 text-small">DASHBOARD</span>
                </router-link>
                <router-link class="nav-item flex items-center p-2" to="/news">
                    <NewsIcon class="icon pb-1"/>
                    <span class="ml-4 text-small">NEWS</span>
                </router-link>
                <router-link class="nav-item flex items-center p-2" :class="{ 'router-link-exact-active': ($route.name === 'nft' || $route.name === 'ntfs')}" to="/nfts">
                    <HexagonIcon class="icon" />
                    <span class="ml-4 text-small">MARKETPLACE</span>
                </router-link>
                <router-link class="nav-item flex items-center p-2" to="/schools">
                    <SchoolIcon class="icon"/>
                    <span class="ml-4 text-small">CONNECT A SCHOOL</span>
                </router-link>
                <router-link class="nav-item flex items-center p-2" to="/enter-giveaway">
                    <GiftIcon class="icon"/>
                    <span class="ml-4 text-small">ENTER GIVEAWAY</span>
                </router-link>
                <router-link class="nav-item flex items-center p-2" to="/staking">
                    <AirIcon class="icon" />
                    <span class="ml-4 text-small">STAKING</span>
                </router-link>
                <router-link class="nav-item flex items-center p-2" to="/swap">
                    <SwapIcon class="icon" />
                    <span class="ml-4 text-small">SWAP</span>
                </router-link>
                <router-link v-if="$store.getters.loggedIn" class="nav-item flex items-center p-2" to="/claim">
                    <AirIcon class="icon" />
                    <span class="ml-4 text-small">CLAIM 3AIR</span>
                </router-link>
                <router-link class="nav-item flex items-center p-2" to="/staking-calculator">
                    <CalculatorIcon class="icon" />
                    <span class="ml-4 text-small">STAKING CALCULATOR</span>
                </router-link>
                <router-link class="nav-item flex items-center p-2" to="/coverage-map">
                    <fa icon="location-dot" class="icon"/>
                    <span class="ml-4 text-small">COVERAGE MAP</span>
                </router-link>
                <router-link class="nav-item flex items-center p-2" :to="{name: 'collection', params: {id: 'BSC-4'}}">
                    <HexagonIcon class="icon" />
                    <span class="ml-4 text-small">CONNECTIVITY NFTs</span>
                </router-link>
                <router-link class="nav-item flex items-center p-2" to="/3air">
                    <AirIcon class="icon" />
                    <span class="ml-4 text-small">3AIR TOKEN</span>
                </router-link>
            </div>
        </template>
    </drop-component>
</template>
<script>
    import DropComponent from '@/components/DropComponent'
    import AirIcon from '@/assets/icons/AirIcon'
    import HouseIcon from '@/assets/icons/HouseIcon'
    import HexagonIcon from '@/assets/icons/HexagonIcon'
    import CalculatorIcon from '@/assets/icons/CalculatorIcon'
    import SchoolIcon from '@/assets/icons/SchoolIcon'
    import NewsIcon from '@/assets/icons/NewsIcon'
    import AnimatedHamburgerIcon from '@/assets/icons/AnimatedHamburgerIcon'
    import SwapIcon from '@/assets/icons/SwapIcon'
    import GiftIcon from '@/assets/icons/GiftIcon.vue'

    export default  {
        components: {
            SwapIcon,
            AnimatedHamburgerIcon,
            DropComponent,
            AirIcon,
            HouseIcon,
            HexagonIcon,
            CalculatorIcon,
            SchoolIcon,
            NewsIcon,
            GiftIcon
        },
        data() {
            return {
                isMenuOpen: false
            }
        },
        methods: {
            onDropdownChanged(open) {
                this.isMenuOpen = open
            },
            onHamburgerIconClicked() {
                this.isMenuOpen = !this.isMenuOpen
            }
        }
    }
</script>
<style scoped lang="scss">
@import '@/assets/scss/style.scss';
.nav-item:hover {
  color: $text-danger;
}
.icon {
  width: 22px;
}
</style>
