import Vue from 'vue'
import Vuex from 'vuex'

import chainStoreInitializer from './chain'
import user from './user'
import app from './app'
import {EthereumProvider} from '@walletconnect/ethereum-provider'

Vue.use(Vuex)

let store = undefined

async function reconnectWallet(store) {

  const lastConnection = localStorage.getItem('last_connection')
  if (!lastConnection) return

  if (lastConnection === 'window.ethereum') {

    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({method: 'eth_accounts'})
        if (accounts.length > 0) {
          await store.dispatch('connectMetamask')
        }
      } catch (e) {
        Vue.prototype.$printError('There was an error connecting to Metamask. Please refresh your website!')
      }
    }

  } else if (lastConnection === 'wc') {
    if (!store.state.chain.connectedWalletData.walletConnectProvider.session) return
    await store.dispatch('connectWalletConnect')
  }

}

const storeInitializer = async() => {

  const chain = chainStoreInitializer(Vue.prototype.appConfig.chains)

  store = new Vuex.Store({
    modules: {
      chain,
      user,
      app
    },
    strict: process.env.DEV
  })


  store.state.chain.connectedWalletData.walletConnectProvider = await EthereumProvider.init({
    projectId: '3c6f98b02f0a4a19e62200f1dbf1b567',
    chains: ['56'],
    showQrModal: true,
    methods: ['eth_sendTransaction', 'personal_sign', 'eth_signTypedData', 'eth_signTypedData_v4', 'eth_sign'],
    events: ['chainChanged', 'accountsChanged', 'disconnect']
    /*metadata: {
        name: 'MetFi APP',
        url: 'https://app.metfi.io',
        icons: ['https://app.metfi.io/img/icons/icon-200x200.png']
    }*/
  })

  await reconnectWallet(store)

  return store
}

export {storeInitializer, store as default}
