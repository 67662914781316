import {ethers} from 'ethers'
import Vue from 'vue'
import store from '@/store'
import chainAddresses from '@/libs/blockchain_contracts/chainAddresses'

const stakingABI = require('./ABI/3airStaking.sol/AirStaking.json')

const stakingContract = () => new ethers.Contract(chainAddresses.bsc.staking, stakingABI, store.state.chain.readProviders.bsc)

Vue.prototype.$stakingContract = stakingContract
export default stakingContract