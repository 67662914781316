<template>

    <drop-component class="relative w-full" drop-class="default" additional-dropdown-class="w-full" style="height: 35px;">
        <template #button>
            <div class="flex items-center">
                <jazzicon class="img-profile-main -mr-4" :address="$store.getters.address" :diameter="35" />
                <div class="address-div bg-primary py-1.5 px-2 border-rounded hidden sm:flex items-center">
                    <p class="font-weight-medium text-small pl-4">
                        {{getShortAddress}}
                    </p>
                </div>
            </div>
        </template>
        <template #content>
            <div class="text-secondary links flex flex-col text-right pr-4 gap-4 py-4">
                <router-link class="font-weight-medium" to="/my-nfts" >My NFTs</router-link>
                <a class="cursor-pointer font-weight-medium" @click="logout">Logout</a>
            </div>
        </template>
    </drop-component>
</template>
<script>
    import DropComponent from '@/components/DropComponent'
    import Jazzicon from 'vue-jazzicon'

    export default {
        components: {DropComponent, Jazzicon},
        methods: {
            async logout() {
                await this.$store.dispatch('disconnectWallet')
            }
        },
        computed: {
            getShortAddress() {
                if (!this.$store.state.chain.accountData.address) return ''
                const wallet = this.$store.state.chain.accountData.address
                return `${wallet.substring(0, 6)}...${wallet.substring(wallet.length - 5, wallet.length)}`
            }
        }
    }
</script>

<style scoped lang="scss">
@import '@/assets/scss/style.scss';
.img-profile-main {
  width: 35px;
  height: 35px;
  z-index: 1;
}
.address-div {
  box-shadow: 7px 9px 6px rgba(0, 0, 0, 0.3), inset 6px 6px 20px rgba(0, 0, 0, 0.25);
  height: 35px;
}
.links a {
  color: #828282;
}
.links a:hover {
  color: #EB1E4E;
}
</style>
