import { BigNumber, ethers } from 'ethers'
import busdContract from '@/libs/blockchain_contracts/busd'
import chainAddresses from '@/libs/blockchain_contracts/chainAddresses'
import usdtContract from '@/libs/blockchain_contracts/usdt'
import Vue from 'vue'
import vestingContract from '@/libs/blockchain_contracts/vesting'
import axios from 'axios'
import airContract from '@/libs/blockchain_contracts/3air'
import pools from '@/libs/blockchain_contracts/pools.json'
import pancakePairABI from '@/libs/blockchain_contracts/ABI/IPancakePair.sol/IPancakePair.json'
import store from '@/store'
import stakingContract from '@/libs/blockchain_contracts/staking'
import stakingAPYCalculatorContract from '@/libs/blockchain_contracts/stakingAPYCalculator'
import lpTokenContract from '@/libs/blockchain_contracts/lpToken'

const chainStoreInitializer = (networks) => {
  return {
    state: {
      networks,
      readProviders: networks.reduce((allNetworks, currentNetwork) => {
        allNetworks[currentNetwork.codename] = new ethers.providers.JsonRpcProvider(currentNetwork.rpc)
        return allNetworks
      }, {}),
      browserWalletAvailable: window.ethereum !== undefined,

      accountData: {
        address: null,

        accountDataLoaded: false,

        activeVestingTerms: [],


        staking: {
          firstDistributionDone: false,
          stakingTerms: [],
          unstakingRequests: [],
          earnedTokens: 0,
          apy: 0,
          amountToDistribute: 0,
          totalCommitted: 0,
          lockedLP:0,
          stakedLP: 0,
          myStake: 0,
          claimableRewards: 0,
          inUnstaking: 0
        },

        busdBalance: 0,
        busdAllowance: 0,

        reserves: {
          busd: BigNumber.from(0),
          air: BigNumber.from(0)
        },

        balances: {
          bsc: {
            busd: 0, air: 0, staked_air: 0, lp: 0, bnb: 0
          },
          okc: {
            usdt: 0, okc: 0
          }
        },
        allowances: {
          bsc_staking: {
            air: 0, lp: 0
          },
          bsc: {
            busd: 0, air: 0
          },
          okc: {
            usdt: 0
          }
        }
      },

      connectedWalletData: {
        chainId: null,
        rawProvider: null,
        ethersProvider: null,
        signer: null,
        usedProvider: 0,
        selectedChain: null,
        accountsRequested: false,
        walletConnectProvider: null
      },
      loaded: false
    },
    getters: {
      usdPrice3Air: (state) => {
        const outReserve = state.accountData.reserves.busd
        const inReserve = state.accountData.reserves.air

        const outputAmountBig = ethers.utils.parseUnits('1.0', 18)
        const numerator = inReserve.mul(outputAmountBig).mul(10000)
        const denominator = outReserve.sub(outputAmountBig).mul(9975)
        const inputAmountBig = numerator.div(denominator).add(1)
        return ethers.utils.formatUnits(inputAmountBig, 18)
      },
      balancesNormalized(state) {
        return {
          bsc: {
            busd: Number(ethers.utils.formatUnits(state.accountData.balances.bsc.busd, 18)),
            air: Number(ethers.utils.formatUnits(state.accountData.balances.bsc.air, 18)),
            staked_air: Number(ethers.utils.formatUnits(state.accountData.balances.bsc.staked_air, 18)),
            lp: Number(ethers.utils.formatUnits(state.accountData.balances.bsc.lp, 18)),
            bnb: Number(ethers.utils.formatUnits(state.accountData.balances.bsc.bnb, 18))
          },
          okc: {
            usdt: Number(ethers.utils.formatUnits(state.accountData.balances.okc.usdt, 18)),
            okc: Number(ethers.utils.formatUnits(state.accountData.balances.okc.okc, 18))
          }
        }
      },
      allowancesNormalized(state) {
        return {
          bsc_staking: {
            lp: Number(ethers.utils.formatUnits(state.accountData.allowances.bsc_staking.lp, 18)),
            air: Number(ethers.utils.formatUnits(state.accountData.allowances.bsc_staking.air, 18))
          },
          bsc: {
            busd: Number(ethers.utils.formatUnits(state.accountData.allowances.bsc.busd, 18)),
            air: Number(ethers.utils.formatUnits(state.accountData.allowances.bsc.air, 18))
          },
          okc: {
            usdt: Number(ethers.utils.formatUnits(state.accountData.allowances.okc.usdt, 18))
          }
        }
      },
      networkByName(state) {
        return (networkToFind) => state.networks.find((network) => {
          return network.id === networkToFind || network.codename === networkToFind || network.id_hex === networkToFind
        })
      },
      totalAirTokens(state) {
        return (state.accountData.activeVestingTerms.reduce((total, currentTerm) => {
          return total + Number(ethers.utils.formatUnits(currentTerm.vestedAmount, 18)) - Number(ethers.utils.formatUnits(currentTerm.tokensClaimed, 18))
        }, 0) + Number(ethers.utils.formatUnits(state.accountData.balances.bsc.air, 18)) + Number(ethers.utils.formatUnits(state.accountData.balances.bsc.staked_air, 18))).toLocaleString()
      },
      address(state) {
        return state.accountData.address
      }
    },
    mutations: {},
    actions: {
      async disconnectWallet({ state }) {
        if (state.connectedWalletData.usedProvider === 2) {
          localStorage.removeItem('last_connection')
          Object.keys(localStorage)
            .filter(x => x.startsWith('wc@2'))
            .forEach(x => localStorage.removeItem(x))
          await state.connectedWalletData.walletConnectProvider.disconnect()
        }
        localStorage.removeItem('last_connection')
        window.location.reload()
      },
      async connectMetamask({ state, dispatch }) {

        if (state.connectedWalletData.accountsRequested) return

        try {

          state.connectedWalletData.rawProvider = window.ethereum
          state.connectedWalletData.ethersProvider = new ethers.providers.Web3Provider(window.ethereum)
          state.connectedWalletData.usedProvider = 1

          state.connectedWalletData.accountsRequested = true
          const addresses = await window.ethereum.request({ method: 'eth_requestAccounts' })
          state.connectedWalletData.accountsRequested = false

          if (addresses.length > 0) {
            const network = await state.connectedWalletData.ethersProvider.getNetwork()
            await dispatch('handleNetworkSet', ethers.utils.hexlify(network.chainId))
            await dispatch('walletAddressChanged', addresses[0])
          }

          window.ethereum.on('chainChanged', async(e) => {
            if (state.connectedWalletData.usedProvider !== 1) return
            state.connectedWalletData.ethersProvider = new ethers.providers.Web3Provider(window.ethereum)
            await dispatch('handleNetworkSet', e)
          })

          window.ethereum.on('accountsChanged', async(accounts) => {
            if (state.connectedWalletData.usedProvider !== 1) return
            await dispatch('walletAddressChanged', (accounts.length > 0) ? accounts[0] : null)
          })

          localStorage.setItem('last_connection', 'window.ethereum')

        } catch (e) {
          Vue.prototype.$printError('There was an error connecting to Metamask. Please refresh your website!')
          state.connectedWalletData.accountsRequested = false
        }
      },
      async connectWalletConnect({ state, dispatch }) {

        try {
          await state.connectedWalletData.walletConnectProvider.enable()

          state.connectedWalletData.walletConnectProvider.on('accountsChanged', async(accounts) => {
            if (state.connectedWalletData.usedProvider !== 2) return
            await dispatch('walletAddressChanged', (accounts.length > 0) ? accounts[0] : null)
          })

          state.connectedWalletData.walletConnectProvider.on('chainChanged', async(chainId) => {
            if (state.connectedWalletData.usedProvider !== 2) return
            state.connectedWalletData.ethersProvider = new ethers.providers.Web3Provider(state.connectedWalletData.walletConnectProvider)
            await dispatch('handleNetworkSet', ethers.utils.hexZeroPad(chainId, 1))
          })

          state.connectedWalletData.walletConnectProvider.on('disconnect', () => {
            localStorage.removeItem('last_connection')
            window.location.reload()
          })

          state.connectedWalletData.rawProvider = state.connectedWalletData.walletConnectProvider
          state.connectedWalletData.ethersProvider = new ethers.providers.Web3Provider(state.connectedWalletData.walletConnectProvider)
          state.connectedWalletData.usedProvider = 2
          const network = await state.connectedWalletData.ethersProvider.getNetwork()
          dispatch('handleNetworkSet', ethers.utils.hexlify(network.chainId))
          dispatch('walletAddressChanged', state.connectedWalletData.walletConnectProvider.accounts[0])

          localStorage.setItem('last_connection', 'wc')

        } catch (e) {
          Vue.prototype.$printError('There was an error connecting to Wallet Connect. Please refresh your website!')
        }
      },
      async switchChain({ state }, newNetwork) {
        if (state.connectedWalletData.usedProvider === 2) return

        const selectedChain = networks.find((network) => {
          return network.id === newNetwork || network.codename === newNetwork
        })
        if (!selectedChain) return

        const chainData = {
          chainId: selectedChain.id_hex,
          chainName: selectedChain.name,
          nativeCurrency: selectedChain.nativeCurrency,
          rpcUrls: [selectedChain.rpc],
          blockExplorerUrls: [selectedChain.explorerUrl]
        }

        try {
          await state.connectedWalletData.rawProvider.request({
            method: 'wallet_switchEthereumChain', params: [{ chainId: selectedChain.id_hex }]
          })
        } catch (switchError) {
          if (switchError.code === 4902 || switchError.data.originalError.code === 4902 || (switchError.toString() && switchError.toString().includes('wallet_addEthereumChain'))) {
            try {
              await state.connectedWalletData.rawProvider.request({
                method: 'wallet_addEthereumChain', params: [chainData]
              })
            } catch (addError) {
              Vue.prototype.$printError('There was an error adding the new network. Please add it manually!')
            }
          } else {
            Vue.prototype.$printError('There was an error switching chains. Please refresh your website!')
          }
        }
      },
      async walletAddressChanged({ state, dispatch }, address) {

        try {
          if (address) {
            state.accountData.address = ethers.utils.getAddress(address)
            state.connectedWalletData.signer = state.connectedWalletData.ethersProvider.getSigner()
            dispatch('loadAccountDataFromChain')
          } else {
            state.accountData.address = address
          }
          if (state.loaded) {
            await axios.get('/api/user/v1/logout')
          }
          state.loaded = true
        } catch (e) {
          Vue.prototype.$printError('There was an error retrieving data from your wallet. Please refresh your website!')
        }
      },
      async handleNetworkSet({ state }, chainId) {
        try {
          state.connectedWalletData.chainId = chainId
          state.connectedWalletData.signer = state.connectedWalletData.ethersProvider.getSigner()
        } catch (e) {
          Vue.prototype.$printError('There was an error connecting to your wallet. Please refresh your website!')
        }
      },
      async refresh3AirBusdReservesFromChain({ state }) {
        const pair = new ethers.Contract(pools.bsc[chainAddresses.bsc.busd], pancakePairABI, store.state.chain.readProviders['bsc'])
        const token0Address = await pair.token0()
        const reserves = await pair.getReserves()
        if (token0Address === chainAddresses.bsc.air) {
          state.accountData.reserves.air = reserves.reserve0
          state.accountData.reserves.busd = reserves.reserve1
        } else {
          state.accountData.reserves.air = reserves.reserve1
          state.accountData.reserves.busd = reserves.reserve0
        }
      },
      async loadAccountDataFromChain({ state, dispatch }) {

        state.accountData.accountDataLoaded = false
        try {

          dispatch('loadUserOwnedNFTs')
          const stakingPromise = stakingContract().getStakingTerms(state.accountData.address).then((terms) => {

            state.accountData.staking.stakingTerms = terms.map((term) => {
              return {
                id: term.ID,
                availableTokens: Number(ethers.utils.formatUnits(term.availableTokens)),
                lpTokenIndex: Number(term.lpTokenIndex),
                stakedTokenType: Number(term.stakedTokenType),
                stakedTokens: Number(ethers.utils.formatUnits(term.stakedTokens)),
                stakedTokensRaw: term.stakedTokens,
                startIndex: Number(term.startIndex),
                earnedTokens: Number(ethers.utils.formatUnits(term.earnedTokens))
              }
            })

            state.accountData.balances.bsc.staked_air = terms.reduce((total, current) => {
              return total + current.stakedTokens
            }, Number(0))


            if (state.accountData.staking.stakingTerms && state.accountData.staking.stakingTerms.length > 0) {
              state.accountData.staking.myStake = state.accountData.staking.stakingTerms.reduce((total, item) => {

                let staked = 0
                if (item.stakedTokenType === 0) {
                  staked = item.stakedTokens
                }
                return total + staked
              }, 0)

              state.accountData.staking.claimableRewards = state.accountData.staking.stakingTerms.reduce((total, item) => {
                let rewards = 0
                if (item.stakedTokenType === 0) {
                  rewards = item.earnedTokens
                }
                return total + rewards
              }, 0)

            }
          })


          const unstakingPromise = stakingContract().getUnstakingRequests(state.accountData.address).then((requests) => {

            state.accountData.staking.unstakingRequests = requests.map((request, index) => {
              return {
                id: index,
                lpTokenIndex: request.lpTokenIndex,
                stakedTokenType: request.stakedTokenType,
                unstakingAmount: Number(ethers.utils.formatUnits(request.unstakingAmount)),
                unstakingAvailableTimestamp: Number(request.unstakingAvailableTimestamp)
              }
            })

            if (state.accountData.staking.unstakingRequests && state.accountData.staking.unstakingRequests.length > 0) {
              state.accountData.staking.inUnstaking = state.accountData.staking.unstakingRequests.reduce((total, item) => {
                let rewards = 0
                if (item.stakedTokenType.eq(0)) {
                  rewards = item.unstakingAmount
                }
                return total + rewards
              }, 0)
            } else {
              state.accountData.staking.inUnstaking = 0
            }
          })


          const earnedPromise = stakingContract().earnedTokens(state.accountData.address).then((earnedTokens) => {
            state.accountData.staking.earnedTokens = Number(ethers.utils.formatUnits(earnedTokens))
          });

          [state.accountData.balances.bsc.bnb, state.accountData.activeVestingTerms, state.accountData.balances.bsc.busd, state.accountData.allowances.bsc.busd, state.accountData.balances.bsc.air, state.accountData.allowances.bsc.air, state.accountData.allowances.bsc_staking.air, state.accountData.balances.bsc.lp, state.accountData.allowances.bsc_staking.lp] = await Promise.all([store.state.chain.readProviders['bsc'].getBalance(state.accountData.address), vestingContract().getActiveTerms(state.accountData.address), busdContract('bsc').balanceOf(state.accountData.address), busdContract('bsc').allowance(state.accountData.address, chainAddresses.bsc.collectibleNFTContract), airContract().balanceOf(state.accountData.address), airContract().allowance(state.accountData.address, chainAddresses.bsc.collectibleNFTContract), airContract().allowance(state.accountData.address, chainAddresses.bsc.staking), lpTokenContract().balanceOf(state.accountData.address), lpTokenContract().allowance(state.accountData.address, chainAddresses.bsc.staking), stakingPromise, unstakingPromise, earnedPromise, dispatch('loadPublicDataFromChain')])

          state.accountData.balances.okc.okc = await store.state.chain.readProviders['okc'].getBalance(state.accountData.address)
          state.accountData.balances.okc.usdt = await usdtContract('okc').balanceOf(state.accountData.address)
          state.accountData.allowances.okc.usdt = await usdtContract('okc').allowance(state.accountData.address, chainAddresses.okc.collectibleNFTContract)

        } catch (e) {
          console.error(e)
          // Vue.prototype.$printError('There was an error retreiving your wallet balance. Please refresh your website!')
        }

        state.accountData.accountDataLoaded = true
      },
      async loadPublicDataFromChain({ state }) {

        try {

          const APYpromise = stakingAPYCalculatorContract().getAPY().then((apy) => {
            state.accountData.staking.apy = Number(apy)
          })
          const stakedPromise = stakingContract().stakedTokens().then((stakedTokens) => {
            state.accountData.staking.totalCommitted = Number(ethers.utils.formatUnits(stakedTokens))
          })

          const indexPromise = stakingContract().totalIndexes().then((totalIndexes) => {
            state.accountData.staking.totalIndexes = totalIndexes

            stakingContract().getAmountToDistribute((Number(state.accountData.staking.totalIndexes) + 1)).then((amountToDistribute) => {

              state.accountData.staking.amountToDistribute = Number(ethers.utils.formatUnits(amountToDistribute.toString()))
            })

            if (Number(state.accountData.staking.totalIndexes) > 0) {

              state.accountData.staking.firstDistributionDone = true

              return stakingContract().getLastIndex().then((lastIndexAll) => {
                state.accountData.staking.lastIndex = {
                  freeTokensStaked: Number(lastIndexAll.freeTokensStaked),
                  timestamp: Number(lastIndexAll.timestamp),
                  tokensToDistribute: Number(lastIndexAll.tokensToDistribute),
                  totalTokesStaked: Number(lastIndexAll.totalTokesStaked)

                }
                state.accountData.staking.lastDistributionDate = state.accountData.staking.lastIndex.timestamp
                state.accountData.staking.nextDistributionDate = state.accountData.staking.lastIndex.timestamp + (24 * 7 * 60 * 60)
              })

            } else {
              return stakingContract().firstIndexIncrease().then((firstIndexIncrease) => {
                state.accountData.staking.lastDistributionDate = 1663624800
                state.accountData.staking.nextDistributionDate = Number(firstIndexIncrease)
              })


            }
          })

          const reservesPromise = new Promise((resolve) => {
            const pair = new ethers.Contract(pools.bsc[chainAddresses.bsc.busd], pancakePairABI, store.state.chain.readProviders['bsc'])
            pair.token0().then((token0Address) => {
              pair.getReserves().then((reserves) => {
                if (token0Address === chainAddresses.bsc.air) {
                  state.accountData.reserves.air = reserves.reserve0
                  state.accountData.reserves.busd = reserves.reserve1
                } else {
                  state.accountData.reserves.air = reserves.reserve1
                  state.accountData.reserves.busd = reserves.reserve0
                }
                resolve()
              })
            })

          });


          [state.accountData.staking.lockedLP, state.accountData.staking.stakedLP] = await Promise.all([lpTokenContract().balanceOf(chainAddresses.bsc.lpLiquidityLockContract), lpTokenContract().balanceOf(chainAddresses.bsc.staking)])

          await Promise.all([APYpromise, stakedPromise, indexPromise, reservesPromise])

        } catch (e) {
          console.error(e)
          Vue.prototype.$printError('There was an error retreiving data from blockchain. Please refresh your website!')
        }
      }
    }
  }
}

export default chainStoreInitializer
