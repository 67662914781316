<template>
    <div id="app" class="md:pt-8 mx-4 sm:mx-6 md:mx-20 lg:mx-20 bg-primary">
        <nav v-if="$route.name !== 'login' && $route.name !== 'error-404'" class="navbar w-full fixed md:relative top-0 z-50" ref="navigation-bar">
            <desktop-navbar class="hidden md:block" id="desktop-navbar"/>
            <mobile-navbar class="z-50 md:hidden" id="mobile-navbar"/>
            <div class="z-30 bg-primary grid grid-cols-12 lg:mt-4 w-full mt-4 pb-3 pr-12 md:pr-0"
                 v-if="showGoBackButton || showSearchBar"
                 id="searchbar-wrapper">
                <div class="col-span-12 lg:col-span-8 lg:col-start-3 flex flex-row">
                    <div v-if="showGoBackButton" @click="$router.back()" class="mr-8 mt-1 cursor-pointer">
                        <img :src="require(`@/assets/go-back-button.svg`)"/>
                    </div>
                    <Search v-if="showSearchBar" class="w-full"/>
                </div>
            </div>
        </nav>
        <div class="w-full h-full">
            <div ref="router-view" class="w-full h-full grid grid-cols-12 py-4">
                <router-view class="lg:col-start-3 col-span-12 lg:col-span-8"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Search from '@/components/Search'
    import DesktopNavbar from '@/components/navbar/DesktopNavbar'
    import MobileNavbar from '@/components/navbar/MobileNavbar'
    export default {
        components: {
            MobileNavbar,
            DesktopNavbar,
            Search
        },
        data() {
            return {
                switchChainClicked: false,
                screenWidth: 0
            }
        },
        methods: {
            async logout() {
                await this.$store.dispatch('disconnectWallet')
            },
            changeChain(chain) {
                this.$store.dispatch('switchChain', chain.shortName.toLowerCase())
                this.$refs.switchChains.show = false
                this.$refs.switchChains.mouseover = false
            },
            balance(name) {
                let fullText = ''

                switch (name.toLowerCase()) {
                case 'bsc':
                    fullText += `${this.$store.getters.balancesNormalized.bsc.busd.toFixed(2)} BUSD`
                    break
                case 'okc':
                    fullText += `${this.$store.getters.balancesNormalized.okc.usdt.toFixed(2)} USDT`
                    break
                }
                return fullText
            },
            isMobile() {
                return window.innerWidth <= 868
            },

            async calculateTopOffsetOnMobile() {
                const routerView = this.$refs['router-view']

                // for example when going from Dashboard (which doesn't have Searchbar) to marketplace (which has Searchbar),
                // sometimes searchbar isn't rendered yet (and is therefore null) before this function is called
                // waiting for nextTick solves that
                await this.$nextTick()

                if (!this.isMobile() || this.$route.name === null || this.$route.name === 'login') {
                    routerView.style.marginTop = '0px'
                    return
                }

                // search wrapper is div, that contains search component and go back button
                const searchWrapper = document.getElementById('searchbar-wrapper')
                const searchWrapperHeight = searchWrapper?.clientHeight ?? 0
                // mobile navbar is a component that contains Logo, hamburger menu, chain dropdown and profile dropdown
                const mobileNavbarHeight = document.getElementById('mobile-navbar').clientHeight ?? 0

                let offsetFromTop = 0

                if (searchWrapper) {
                    // searchbar with go back button sits just beneath mobile menu with position fixed when scrolling
                    // that's why we set top to mobilenavbar height
                    searchWrapper.style.top = `${mobileNavbarHeight}px`
                }

                if (this.showGoBackButton || this.showSearchBar) offsetFromTop = mobileNavbarHeight + searchWrapperHeight
                else offsetFromTop = mobileNavbarHeight

                // router view sits beneath searchbar (if present) and mobile navbar
                routerView.style.marginTop = `${offsetFromTop}px`
            }
        },
        computed:{
            getShortAddress() {
                if (!this.$store.state.chain.accountData.address) return ''
                const wallet = this.$store.state.chain.accountData.address
                return `${wallet.substring(0, 6)}...${wallet.substring(wallet.length - 5, wallet.length)}`
            },
            currentNetwork() {
                return this.$store.getters.networkByName(this.$store.state.chain.connectedWalletData.chainId)
            },
            activeChain() {
                let chain = null

                const currentNetwork = this.currentNetwork
                if (currentNetwork) {
                    for (const item in this.$chains) {
                        if (this.$chains[item].shortName.toLowerCase() === currentNetwork.codename.toLowerCase()) {
                            chain = this.$chains[item]
                        }
                    }
                }
                return chain
            },
            showSearchBar() {
                const hideSearchBarOnRoutes = ['login', 'news', 'claim', 'swap', 'enter-giveaway', 'staking', 'staking-calculator', 'home', 'coverage-map', '3air']
                return !hideSearchBarOnRoutes.includes(this.$route.name)
            },
            showGoBackButton() {
                if (this.isMobile()) return false
                const hideGoBackButtonOnRoutes = [
                    'login', 'news', 'claim', 'nfts', 'home', 'swap', 'enter-giveaway', 'staking', 'staking-calculator', 'nft', '3air',
                    'coverage-map'
                ]
                return !hideGoBackButtonOnRoutes.includes(this.$route.name)
            }
        },
        watch: {
            async $route() {
                await this.calculateTopOffsetOnMobile()
            }
        },
        beforeCreate() {
            const theme = 'dark'
            if (theme === 'dark') {
                document.documentElement.classList.add('dark')
            } else {
                document.documentElement.classList.add('light')
            }
            //  else {
            //     document.documentElement.classList.add('light-layout')
            // }
        },
        mounted() {
            if (!this.$store.getters.getRandomSeed) this.$store.dispatch('setRandomSeed')
            this.$store.dispatch('loadPublicDataFromChain')

            this.calculateTopOffsetOnMobile()
            window.addEventListener('resize', async() => {
                await this.calculateTopOffsetOnMobile()
            })

        },
        beforeDestroy() {
            window.removeEventListener('resize', this.calculateTopOffsetOnMobile)
        }
    }
</script>

<style scoped lang="scss">
@import './assets/scss/style.scss';

#app {
  font-family: 'Comfortaa';
  color: white;
  //overflow-x: hidden;
  min-height: 100vh;
  height: 100%;
}
.back-button {
  position: absolute;
  left: -60px;
  top: 6px;
  cursor: pointer;
}
</style>
<style lang="scss">
@import '@/assets/scss/style.scss';
.v-popper__inner{
  background: $background-primary !important;
  box-shadow: 6px 6px 9px $background-tertiary, inset 20px 20px 40px $background-tertiary;
  backdrop-filter: blur(50px);
  border-radius: 7px;
  margin: 0 25px 0 25px;
  text-align: center;
}

.v-popper__arrow-outer{
  border-color: $background-primary !important;
}
</style>
