import {ethers} from 'ethers'
import Vue from 'vue'
import store from '@/store'
import chainAddresses from '@/libs/blockchain_contracts/chainAddresses'

const erc20ABI = require('./ABI/ERC20.sol/ERC20.json')

const airContract = () => new ethers.Contract(chainAddresses.bsc.air, erc20ABI, store.state.chain.readProviders.bsc)

Vue.prototype.$airContract = airContract
export default airContract