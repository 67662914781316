import { render, staticRenderFns } from "./DesktopNavbar.vue?vue&type=template&id=afdeeeca&scoped=true&"
import script from "./DesktopNavbar.vue?vue&type=script&lang=js&"
export * from "./DesktopNavbar.vue?vue&type=script&lang=js&"
import style0 from "./DesktopNavbar.vue?vue&type=style&index=0&id=afdeeeca&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afdeeeca",
  null
  
)

export default component.exports