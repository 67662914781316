<template>
    <div class="mobile-menu bg-primary p-4">

        <div class="flex items-center justify-start grid grid-cols-12">
            <div class="col-span-1 flex items-center">
                <router-link to="/">
                    <img width="35px" alt="3air Logo" :src="require('@/assets/3air_logo_no_text_white.svg')">
                </router-link>
            </div>
            <div class="col-span-2 flex items-center mt-4 ml-4">
                <mobile-dropdown-menu/>
            </div>
            <div class="w-full col-span-9 flex items-end">

                <div class="ml-auto flex items-center relative">
                    <div v-if="$store.getters.loggedIn" class="mr-4">
                        <chains-dropdown-menu/>
                    </div>

                    <div v-if="$store.getters.loggedIn" class="mr-2">
                        <profile-dropdown-menu/>
                    </div>
                    <Button v-else @click="$router.push({name: 'login'})" class="mx-2">CONNECT WALLET</Button>
                    <three-dots-menu class="mx-2"/>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import ProfileDropdownMenu from '@/components/navbar/components/ProfileDropdownMenu'
    import ChainsDropdownMenu from '@/components/navbar/components/ChainsDropdownMenu'
    import ThreeDotsMenu from '@/components/navbar/components/ThreeDotsMenu'
    import MobileDropdownMenu from '@/components/navbar/components/MobileDropdownMenu'
    import Button from '@/components/Button'
    export default {
        components: {
            MobileDropdownMenu,
            ProfileDropdownMenu,
            ChainsDropdownMenu,
            ThreeDotsMenu,
            Button
        }
    }
</script>

<style scoped>

.mobile-menu {
  width: 100%;
  z-index: 10;
}
</style>
