<template>
    <div>
        <div class="flex items-center input-div py-2 px-2 border-rounded">
            <input class="w-full input-div focus:outline-none px-4" placeholder="Search" type="text" v-model="searchQuery" style="height: 35px;" @keyup="search" @keyup.enter="searchOnEnterPress" @blur="onFormBlur" @update="isSearchFocused = true" @click="isSearchFocused = true"/>
            <fa class="mx-4" icon="search" size="xl"/>
        </div>
        <div class="navbar-container content searchResultsDropdown">
            <div class="search-input open p-0 m-0 relative">
                <ul class="drop-search absolute w-full" style="max-height: 400px; overflow-y: auto; z-index: 999;" :class="[isSearchFocused && searchQuery ? 'visible':'invisible']">
                    <li v-for="item in searchArray" :key="item.id" @click="onItemFromDropdownSelected(item)" class="suggestion-group-suggestion cursor-pointer">
                        <span>
                            <div class="p-0">{{ $route.name === 'my-nfts' ? item.nft.name : item.name }}</div>
                            <template v-if="!item.titleIncludesQuery && item.fieldSearchResult">
                                <div class="suggestion-description"><small>{{ item.fieldSearchResult.name }}: <span v-html="item.fieldSearchResult.contentWithHTML"></span></small></div>
                            </template>
                        </span>
                    </li>
                    <li v-if="searchQuery !== '' && searchArray.length === 0 && isSearchFocused" class="suggestion-group-suggestion no-results">
                        <span class="p-0">No NTFs to display</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>


    export default {
        data() {
            return {
                select: 'NFTs',
                options: ['NFTs'],
                searchQuery: '',
                searchArray: [],
                searchIndex: '',
                routeName: '',
                additionalFieldsToSearch: [{name: 'author_name', translation: 'Author'}, {name: 'description', translation: 'Description'}, {name: 'collection', translation: 'Collection'}],
                additionalFieldsToSearch2: [{name: 'nft.author_name', translation: 'Author'}, {name: 'nft.description', translation: 'Description'}, {name: 'nft.collection', translation: 'Collection'}],
                isSearchFocused: false,
                isItemBeingSelected: false,
                enterPressed: false
            }
        },
        methods: {
            onFormBlur() {
                // when clicking away from SearchInput, Blur event fires
                // if it's clicking anywhere on page to close dropdown, that's OK
                // but if user is clicking on item from dropdown, isSearchFocused closes dropdown and
                // item from dropdown is not being clicked. Setting small timeout fixes that
                // Edge Chromium, Google Chrome - 100ms
                // Firefox - 150ms
                // Safari - just works
                setTimeout(() => {
                    if (!this.isItemBeingSelected) this.isSearchFocused = false
                }, 150)
            },
            search() {
                if (this.searchQuery !== '') {
                    const thisIns = this
                    const searchParam = this.searchQuery ? `search=${this.searchQuery}` : ''
                    let url = `/api/public/v1/nft?${searchParam}`
                    if (this.$route.name === 'my-nfts') url = `/api/public/v1/nft/minted_nfts/${this.$store.state.chain.accountData.address}?${searchParam}`

                    thisIns.$http.get(url)
                        .then(response => {
                            this.searchArray = response.data.items ?? []
                            this.findMatchingContent()
                        })
                        .catch(error => {
                            thisIns.$printError(`There was an error loading data\n${error.message}`)
                        })
                } else this.searchArray = []
            },
            searchOnEnterPress() {
                if (this.searchQuery === '') {
                    return
                }
                this.enterPressed = true
                this.$router.push({path: '/nfts', query: {'search': this.searchQuery}})
                setTimeout(() => {
                    this.searchQuery = ''
                    this.searchArray = []
                    this.isSearchFocused = false
                }, 150)
            },
            onItemFromDropdownSelected(item) {
                this.isItemBeingSelected = true

                if (this.$route.name === 'my-nfts' || this.$route.name === 'my-nft') this.$router.push({name: 'my-nft', params: {id: item.id}})
                else this.$router.push({name: 'nft', params: {id: item.id}})
                this.searchQuery = ''
                this.searchArray = []
                this.isItemBeingSelected = false
            },
            findMatchingContent() {
                if (!this.searchQuery || !this.searchArray || !this.searchArray.length) return

                // loop through results and check if query was found in title
                // otherwise check other fields
                if (this.$route.name === 'my-nfts') {
                    for (const el of this.searchArray) {
                        el.titleIncludesQuery = el.nft.name.toLowerCase().includes(this.searchQuery.toLowerCase())
                        if (!el.titleIncludesQuery) this.findSearchQueryInFields(el)
                    }
                } else {
                    for (const el of this.searchArray) {
                        el.titleIncludesQuery = el.name.toLowerCase().includes(this.searchQuery.toLowerCase())
                        if (!el.titleIncludesQuery) this.findSearchQueryInFields(el)
                    }
                }

            },
            findSearchQueryInFields(ad) {
                let array = this.additionalFieldsToSearch
                if (this.$route.name === 'my-nfts') array = this.additionalFieldsToSearch2

                for (const field of array) {

                    // content & description usually contains HTML, strip it
                    let strippedHTMLStr = ''

                    if (field.name.includes('.')) {
                        const name = field.name.split('.')
                        if (!ad[name[0]][name[1]]) continue
                        strippedHTMLStr = String(ad[name[0]][name[1]]).replace(/(<([^>]+)>)/gi, '').trim()

                    } else {
                        if (!ad[field.name]) continue
                        strippedHTMLStr = String(ad[field.name]).replace(/(<([^>]+)>)/gi, '').trim()
                    }

                    // find if field includes search query (search everything in lower case)
                    const idx = strippedHTMLStr.toLowerCase().indexOf(this.searchQuery.toLowerCase())
                    if (idx === -1) continue

                    // if string is too long to field in search results, we cut it
                    if (strippedHTMLStr.length > 100) {

                        // we want to cut string in a way, that searched query is gonna be visible somewhere in the middle
                        // (70 characters)__SEARCH QUERY__(30 characters)

                        // if string was found 70 characters or less in the string, we will cut from the start
                        const start = (idx - 70) >= 0 ? (idx - 70) : 0

                        // we will cut from index + 30 (if idx+40 is larger than string length, it will just cut to the end)
                        const end = idx + 30

                        strippedHTMLStr = strippedHTMLStr.substring(start, end)

                        // we append ... to the start, if it didn't cut from first character
                        // and we append .. to the end, if we cut some characters from the end
                        strippedHTMLStr = `${start === 0 ? '' : '...'}${strippedHTMLStr}${end > strippedHTMLStr.length ? '' : '...'}`
                    }

                    const textWithBoldedSearchQuery = strippedHTMLStr.toLowerCase().replace(this.searchQuery.toLowerCase(), `<b class="text-red">${this.searchQuery.toUpperCase()}</b>`)

                    ad.fieldSearchResult = {
                        name: field.translation,
                        contentWithHTML: textWithBoldedSearchQuery
                    }

                    break
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

.input-div{
  background: $input-background;
}

.input-div:after{
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: $background-primary;
  top: -30px;
  left: -15px;
  z-index: -1;
}
.nav-select {
  border-left: 1px solid $text-primary;
  width: 130px !important;
}
li:hover {
  background-color: $background-quinary !important;
  color: $text-tertiary;
//   font-weight: 500;
}
.suggestion-group-suggestion {
  padding: 0.75rem 1rem;
}
li {
  background-color: $input-background;
  border-radius: 0px !important;
}
.suggestion-description {
  padding-top: 0.5em;
  padding-left: 0.8em;
}
input:focus {
  outline: none!important;
}
.drop-search {
  background: $background-primary;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: 8px;
}

</style>
<style>
.nav-select .vs__dropdown-toggle{
  border: none !important;
}

.nav-select .vs__actions {
  padding: 4px 5px 0 5px;;
}
.nav-select .vs__selected {
  padding-left: 5px;
}
.nav-select .vs__dropdown-toggle {
  height: 40px !important;
}
</style>
