import {ethers} from 'ethers'
import Vue from 'vue'
import store from '@/store'
import chainAddresses from '@/libs/blockchain_contracts/chainAddresses'

const erc20ABI = require('./ABI/ERC20.sol/ERC20.json')

const usdtContract = (network) => new ethers.Contract(chainAddresses[network].usdt, erc20ABI, store.state.chain.readProviders[network])

Vue.prototype.$usdtContract = usdtContract
export default usdtContract